<template>
  <div class="textmessage">
    <!-- 对象存储-aliyun -->
    <div v-if="detalform == 'aliyun'">
      <div class="textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label-width="260px" label="存储空间名称（Bucket）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.aliyun_oss.bucket']"></el-input>
            <p>请设置存储空间为公共读</p>
          </el-form-item>

          <el-form-item style="margin-top:24px;" label-width="260px" label="Endpoint（或自定义域名）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.aliyun_oss.endpoint']" class="diy--big"></el-input>
            <p class="tpt01">例子：http://oss-cn-hangzhou.aliyuncs.com，请加上http://或https://，结尾不需要/</p>
          </el-form-item>
        </el-form>
        <div class="theswitch">
          是否开启自定义域名：
          <el-switch @change="inpubtn" v-model.trim="ruleForm['file_storage.aliyun_oss.open_cname']"
            active-color="#000D9A" inactive-color="#bbb"></el-switch>
        </div>
      </div>
      <div class="textmessage_bottom">
        <el-form v-show="kflsre" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
          class="demo-ruleForm">
          <el-form-item label-width="260px" label="Access Key ID：">
            <el-input clearable v-model.trim="ruleForm['file_storage.aliyun_oss.access_key_id']"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="Access Key Secret：">
            <el-input clearable v-model.trim="ruleForm['file_storage.aliyun_oss.access_key_secret']"
              class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="图片样式接口（选填）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.aliyun_oss.style_api']"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 对象存储-腾讯云 -->
    <div v-if="detalform == 'tencent'" class="textmessage_bottom_tx">
      <div class="textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label-width="260px" label="存储空间名称（Bucket）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.tencent_cos.bucket']"></el-input>
            <p>请设置存储空间为公共读</p>
          </el-form-item>

          <el-form-item style="margin-top:24px;" label-width="260px" label="Endpoint（或自定义域名）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.tencent_cos.default_domain']"
              class="diy--big"></el-input>
            <p class="tpt01">例子：http://oss-cn-hangzhou.aliyuncs.com，请加上http://或https://，结尾不需要/</p>
          </el-form-item>
        </el-form>

      </div>
      <div class="textmessage_bottom">
        <el-form style="margin-top:-25px;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
          class="demo-ruleForm">
          <el-form-item label-width="260px" label="Access Key ID：">
            <el-input clearable v-model.trim="ruleForm['file_storage.tencent_cos.access_key_id']"
              class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="Access Key Secret：">
            <el-input clearable v-model.trim="ruleForm['file_storage.tencent_cos.access_key_secret']"
              class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="腾讯云自定义：">
            <el-input clearable v-model.trim="ruleForm['file_storage.tencent_cos.self_domain']"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 对象存储- 七牛云-->
    <div v-if="detalform == 'qiniu'" class="textmessage_bottom_hw">
      <div class="textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label-width="260px" label="存储空间名称（Bucket）：">
            <el-input clearable v-model.trim="ruleForm['file_storage.qiniu_kodo.bucket']"></el-input>
            <p>请设置存储空间为公共读</p>
          </el-form-item>

          <el-form-item style="margin-top:24px;" label-width="260px" label="七牛云自定义域名：">
            <el-input clearable v-model.trim="ruleForm['file_storage.qiniu_kodo.self_domain']"></el-input>
            <p class="tpt01">例子：http://oss-cn-hangzhou.aliyuncs.com，请加上http://或https://，结尾不需要/</p>
          </el-form-item>
        </el-form>
      </div>
      <div class="textmessage_bottom">
        <el-form style="margin-top:-25px;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
          class="demo-ruleForm">
          <el-form-item label-width="260px" label="Access Key ID：">
            <el-input clearable v-model.trim="ruleForm['file_storage.qiniu_kodo.access_key_id']"
              class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="Access Key Secret：">
            <el-input clearable v-model.trim="ruleForm['file_storage.qiniu_kodo.access_key_secret']"
              class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="260px" label="图片样式接口：">
            <el-input clearable v-model.trim="ruleForm['file_storage.qiniu_kodo.style_api']" class="diy--big"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <diy-footer-button>
      <el-button type="primary" @click="cokeibtn">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
export default {
  props: [
    "detalform"
  ],
  data() {
    return {
      kflsre: true,
      value: true,
      radio: "1",
      ruleForm: {},
      rules: {
        'file_storage.aliyun_oss.bucket': [
          {
            required: true,
            message: "请输入存储空间名称",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        KeySecret: [
          {
            required: true,
            message: "请输入Endpoint或自定义域名",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        stencils: [
          { required: true, message: "请输入模板签名", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        keyid: [
          { required: true, message: "请输入Access Key ID", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        secret: [
          {
            required: true,
            message: "请输入Access Key Secret",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        zticon: [
          { required: true, message: "请输入图片样式接口", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.cookeinit();
  },
  methods: {
    // 编辑文件
    cokeibtn() {
      if (this.kflsre == true) {
        this.ruleForm["file_storage.aliyun_oss.open_cname"] = 1;
      } else {
        this.ruleForm["file_storage.aliyun_oss.open_cname"] = 0;
      }
      this.ruleForm["file_storage.type"] = this.detalform;
      this.$put(this.$apis.objecooke, this.ruleForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
      });
    },
    cookeinit() {
      this.$get(this.$apis.objecooke).then(res => {
        this.kflsre = res.data.result["file_storage.aliyun_oss.open_cname"];
        console.log(this.kflsre);
        this.$parent.radio = res.data.result['file_storage.type'];
        if (this.kflsre == 1) {
          this.value = true;
          this.kflsre = true;
        } else {
          this.kflsre = false;
          this.value = false;
        }
        this.ruleForm = res.data.result;
      });
    },
    inpubtn() {
      this.kflsre = !this.kflsre;
    },
    fbuton() {
      this.$router.push("/setup/setup_home");
    },
    butonalli() {
      this.$router.push("/setup/textmessage/alibaba");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {

  .theswitch {
    display: flex;
    margin-left: 140px;
    margin-top: 32px;
  }

  .textmessage_top {
    margin-bottom: 30px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
  }

  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;

    .tpt01 {
      width: 800px;
    }

    p {
      // width: 448px;
      height: 20px;
      font-size: 12px;
      margin-top: -5px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    .textmessage_bottom_text {
      margin-bottom: 38px;
    }

    .iconconte {
      display: flex;
      margin: 20px;
      justify-content: space-around;

      .iconconte_one {
        width: 280px;
        height: 74px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;
        display: flex;
        align-items: center;
        margin: 0 12px;

        img {
          margin-left: 30px;
          width: 39px;
          height: 43px;
        }

        .iconconte_text {
          margin-left: 33px;

          .Interface {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 5px;
            color: #0052d9;
            cursor: pointer;
          }
        }

        /deep/ .el-radio__label {
          display: none;
        }
      }
    }

  }

  .textmessage_bottom_hw {

    .theswitch {
      display: flex;
      margin-left: 140px;
      margin-top: 32px;
    }

    .textmessage_top {
      margin-bottom: 30px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
    }

    .textmessage_bottom {
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;

      p {
        // width: 448px;
        height: 20px;
        font-size: 12px;
        margin-top: -5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      span {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }

      .textmessage_bottom_text {
        margin-bottom: 38px;
      }

      .iconconte {
        display: flex;
        margin: 20px;
        justify-content: space-around;

        .iconconte_one {
          width: 280px;
          height: 74px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddfe6;
          display: flex;
          align-items: center;
          margin: 0 12px;

          img {
            margin-left: 30px;
            width: 39px;
            height: 43px;
          }

          .iconconte_text {
            margin-left: 33px;

            .Interface {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-left: 5px;
              color: #0052d9;
              cursor: pointer;
            }
          }

          /deep/ .el-radio__label {
            display: none;
          }
        }
      }

    }
  }

  .textmessage_bottom_tx {

    .theswitch {
      display: flex;
      margin-left: 140px;
      margin-top: 32px;
    }

    .textmessage_top {
      margin-bottom: 30px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
    }

    .textmessage_bottom {
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;

      p {
        // width: 448px;
        height: 20px;
        font-size: 12px;
        margin-top: -5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      span {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }

      .textmessage_bottom_text {
        margin-bottom: 38px;
      }

      .iconconte {
        display: flex;
        margin: 20px;
        justify-content: space-around;

        .iconconte_one {
          width: 280px;
          height: 74px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddfe6;
          display: flex;
          align-items: center;
          margin: 0 12px;

          img {
            margin-left: 30px;
            width: 39px;
            height: 43px;
          }

          .iconconte_text {
            margin-left: 33px;

            .Interface {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-left: 5px;
              color: #0052d9;
              cursor: pointer;
            }
          }

          /deep/ .el-radio__label {
            display: none;
          }
        }
      }

    }
  }
}
</style>